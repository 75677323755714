<template>
  <div id="container">

    <div v-if="!isRecording && !isSpeak">
      <button @click="toggleRecording" style="background: transparent;border: 0px !important;">
        <div v-if="!loader && !load">
          <img :src="require('@/assets/logo.svg')" class="button-image " />
          <h4 style="color: #02837B;">Size nasıl yardımcı olabilirim?</h4>
        </div>
        <div v-else>
          <i class="fas fa-spinner fa-spin" style="color: #02837B;font-size: 35px;"></i>
          <p style="color: #02837B;font-size: 15px;">Düşünüyorum...</p>
        </div>
      </button>
    </div>
    <div v-else-if="isRecording && !isSpeak">
      <button @click="toggleRecording" style="background: transparent;border: 0px !important;opacity: 0.5 !important;">
        <img :src="require('@/assets/logo.svg')" class="button-image pulse-image" />
      </button>
      <h4 style="color: #02837B;">Dinliyorum...</h4>
    </div>

    <button @click="stopSpeak()" v-else style="background: transparent;border: 0px !important;" align="center" class="w-full">
          <img :src="require('@/assets/logo.svg')" class="button-image " />
          
          <div class="w-full flex justify-center " align="center">
            <div class="boxContainer ">
              <div class="box box1"></div>
              <div class="box box2"></div>
              <div class="box box3"></div>
              <div class="box box4"></div>
              <div class="box box5"></div>
            </div>

          <h4 style="color: #02837B;">Durdurmak için dokunun</h4>

          </div>
      </button>

  </div>
</template>

<script>
import axios from "axios";
import RecordRTC from "recordrtc";


export default {
  props: ['load', 'isSpeak'],
  data() {
    return {
      selectedFile: null,
      recorder: null,
      loader: false,
      textResult: "",
      isRecording: false,
      hasRecording: false,
      apiKey: "AIzaSyBsYLB5kYbjmSX1NZo0aPcWUt7iMEyY5cQ",
      requestData: {
        audio: {
          content: null,
        },
        config: {
          enableAutomaticPunctuation: true,
          encoding: "FLAC",
          sampleRateHertz: 48000,
          languageCode: "tr-TR",
          model: "default",
        },
      },
    };
  },
  methods: {
    stopSpeak(){
      this.$emit('stop',true);
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.recorder = RecordRTC(stream, { type: "audio", mimeType: "audio/wav" });
          this.recorder.startRecording();
          this.isRecording = true;
        })
        .catch((error) => {
          console.error("Error while initializing the recorder:", error);
        });
    },
    stopRecording() {
      if (this.recorder) {
        this.recorder.stopRecording(() => {
          this.isRecording = false;
          this.loader = true;
          this.processRecording();
        });
      }
    },

    processRecording() {
      this.recorder.getDataURL(async (dataURL) => {
        if (dataURL.startsWith("data:audio")) {
          this.audioDataURL = dataURL;

          // WAV dosyasını Blob olarak alın
          const blob = this.recorder.getBlob();

          try {
            // OpenAI API'sini kullanarak sesi metne dönüştürün
            const transcription = await this.transcribeAudio(blob);
            if (transcription) {
              this.$emit("changeMessage", transcription);
              this.textResult = transcription;
            } else {
              this.textResult = "Sonuç bulunamadı.";
            }
          } catch (error) {
            console.error("Transcription error:", error);
          }

          this.loader = false;
          this.hasRecording = true;
        } else {
          console.error("Invalid audio data format.");
        }
      });
    },
    async transcribeAudio(file) {
      const formData = new FormData();
      formData.append("file", file, "audio.wav");
      formData.append("model", "whisper-1");
      formData.append("language", "tr");

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/audio/transcriptions",
          formData,
          {
            headers: {
              Authorization: `Bearer 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`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data && response.data.text) {
          return response.data.text;
        } else {
          console.error("Transcription failed:", response);
          return null;
        }
      } catch (error) {
        console.error("Error in transcribeAudio:", error);
        return null;
      }
    },
    playRecording() {
      if (this.audioDataURL) {
        const audio = new Audio(this.audioDataURL);
        audio.play();
      }
    }
  },
};
</script>


<style>
#container {
  height: 100% !important;
  width: 100% !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center;
  display: flex !important;
}

.button-image {
  width: 200px;
  height: 150px
}


*,
*:before,
*:after {
  box-sizing: inherit;
}



.pulse-image {
  animation: pulse 2s infinite;
  border-radius: 50%;
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(43, 64, 182);
  }

  5% {
    transform: scale(1.01);
  }

  15% {
    transform: scale(1);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(168, 63, 57, 0);
  }

  100% {
    box-shadow: 0 0 0 100px rgba(168, 63, 57, 0);
  }
}

@keyframes quiet {
  25% {
    transform: scaleY(.6);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(.6);
  }
}

@keyframes loud {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(1.2);
  }
}

.boxContainer {
  display: flex;
  width: 60px;
  margin-top: 20px;
  justify-content: space-between;
  height: 38px;
  --boxSize: 5px;
  --gutter: 5px;
  /* width: calc((var(--boxSize) + var(--gutter)) * 5); */
}

.box {
  transform: scaleY(.4);
  height: 100%;
  width: var(--boxSize);
  background: #02837B;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 6px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}



.green {
  background: #66FF99;
}

.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(11, 9, 9);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
