<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { Howl } from "howler";

export default {
  props: ["text"],
  data() {
    return {
      audioSrc: null,
      load: false,
      sound: null,
      howlItem: null
    };
  },
  methods: {
    stop() {
      this.sound.stop();
      this.$emit('endValue', true);
    },
    async synthesizeSpeech() {

      if (!this.text) return;
      this.load = true;

      const apiKey = "AIzaSyBsYLB5kYbjmSX1NZo0aPcWUt7iMEyY5cQ"; // API anahtarınızı buraya yazın
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
      const requestBody = {
        input: {
          text: this.text,
        },
        voice: {
          languageCode: "tr-TR",
          name: "tr-TR-Wavenet-E",
        },
        audioConfig: {
          audioEncoding: "MP3",
          effectsProfileId: ["small-bluetooth-speaker-class-device"],
          pitch: 4.4,
          speakingRate: 1,
        },
      };
      try {
        const response = await axios.post(url, requestBody);
        const audioContent = response.data.audioContent;
        this.audioSrc = `data:audio/mp3;base64,${audioContent}`;
        this.load = false;

        this.sound = new Howl({
          src: [this.audioSrc],
          onplay: () => {
            this.$emit('startValue', true);
          },
          onend: () => {
            this.$emit('endValue', true);
          },
        });

        this.sound.play();
      } catch (error) {
        console.error("Sesli çıktı oluşturma başarısız oldu:", error);
      }
    },
  },

  beforeDestroy() {
    if (this.sound) {
      this.sound.unload();
    }
  },
};
</script>
